import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import carbScreenshot from '../assets/images/carb-stew-recipe-in-cafe.jpg'

function Banner() {
  // const carbScreenshot = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "carb-stew-recipe-in-cafe.jpg" }) {
  //       childImageSharp {
  //         fluid {
  //           base64
  //           aspectRatio
  //           src
  //           srcSet
  //           sizes
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <section id="banner">
      <div className="content">
        <header>
          <h2>Think Simple, Get Carb!</h2>
          <p>Carb: A simple nutrition reference for people with diabetes</p>
          <p>
            <em>Launching soon ...</em>
          </p>
          <p>
            <strong>Enter your email below for updates!</strong>
          </p>
        </header>
        <span className="image">
          <img
            src={carbScreenshot}
            alt="Carb running on an iPhone sitting on a wooden table"
          />
        </span>
      </div>
      <ScrollLink
        to="signup"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={50}
        duration={1500}
        spy={true}
      >
        Next
      </ScrollLink>
    </section>
  )
}

export default Banner
