import React from 'react'
import Fade from 'react-reveal/Fade'

function SignUp() {
  const formInputRef = React.useRef()

  return (
    <section id="signup" className="wrapper style2 special fade inactive">
      <Fade duration={500} onReveal={() => formInputRef.current.focus()}>
        <div className="container">
          <header>
            <h2>Want to learn more?</h2>
            <p>
              Enter your email to get the updates on the app's release and new
              blog posts.
            </p>
          </header>
          <form
            action="https://formcarry.com/s/zbwEeuRsfwBb"
            method="POST"
            acceptCharset="UTF-8"
            className="cta"
          >
            <div className="row gtr-uniform gtr-50">
              <div className="col-4 col-12-xsmall">
                <input
                  ref={formInputRef}
                  aria-label="Your first name"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your first name"
                />
              </div>
              <div className="col-4 col-12-xsmall">
                <input
                  aria-label="Your email address"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your email address"
                />
              </div>
              <div className="col-4 col-12-xsmall">
                <input
                  aria-label="Submit the form"
                  id="submitForm"
                  type="submit"
                  value="Get Updates"
                  className="fit primary"
                />
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </section>
  )
}

export default SignUp
