import React from 'react'
import { Helmet } from 'react-helmet'

import Banner from '../components/Banner'
import Layout from '../components/layout'
import SignUp from '../components/SignUp'

function Home() {
  return (
    <Layout location="/">
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title="Carb"
        meta={[
          {
            name: 'description',
            content: 'Carb for iOS, Nutrition Info for Diabetics',
          },
          {
            name: 'keywords',
            content: 'carb, nutrition, diabetics, diabetes, reference',
          },
        ]}
      />
      <Banner />
      <SignUp />
    </Layout>
  )
}

export default Home
